/*
	############
	#--README--#
	############

	JavaScript for this project is compiled and minified using GULP.

	To build JS in visual studio:
	1. Right click 'gulpfile.js' in the root directory of BaseCMS and select 'Task Runner Explorer'
	   (The 'Task Runner Explorer' window should become visible)
	2. Right click  the 'default' task under the 'Tasks' dropdown and select 'Run'
	   (A new tab labled 'default (runnning)' will appear in the 'Task Runner Explorer' window)

	The 'default' task will also producte several '.js' files in the 'assets/js/gulpBuild' directory
	The 'default' task will produce several '.css' files in the 'assets/css/gulpBuild' directory	

	While the 'default' task is running, changes made to '.scss' files in 'assets/sass' or '.js' files in
		'assets/js' will automatically trigger a SASS or JS build.		
 */


var App = (function () {

	// GLOBAL

	// Determine Browser
	function whatBrowser() {
		var agt = navigator.userAgent.toLowerCase();
		if (agt.indexOf('opera') !== -1) { return 'Opera'; }
		if (agt.indexOf('staroffice') !== -1) { return 'Star Office'; }
		if (agt.indexOf('webtv') !== -1) { return 'WebTV'; }
		if (agt.indexOf('beonex') !== -1) { return 'Beonex'; }
		if (agt.indexOf('chimera') !== -1) { return 'Chimera'; }
		if (agt.indexOf('netpositive') !== -1) { return 'NetPositive'; }
		if (agt.indexOf('phoenix') !== -1) { return 'Phoenix'; }
		if (agt.indexOf('firefox') !== -1) { return 'Firefox'; }
		if (agt.indexOf('chrome') !== -1) { return 'Chrome'; }
		if (agt.indexOf('safari') !== -1) { return 'Safari'; }
		if (agt.indexOf('skipstone') !== -1) { return 'SkipStone'; }
		if (agt.indexOf('msie') !== -1) { return 'Internet Explorer'; }
		if (agt.indexOf('netscape') !== -1) { return 'Netscape'; }
		if (agt.indexOf('mozilla/5.0') !== -1) { return 'Mozilla'; }
		if (agt.indexOf('\/') !== -1) {
			if (agt.substr(0, agt.indexOf('\/')) !== 'mozilla') {
				return navigator.userAgent.substr(0, agt.indexOf('\/'));
			} else { return 'Netscape'; }
		} else if (agt.indexOf(' ') !== -1) {
			return navigator.userAgent.substr(0, agt.indexOf(' '));
		}
		else { return navigator.userAgent; }
	}

	// Determine OS
	function whatOS() {
		if (navigator.appVersion.indexOf('Win') !== -1) { return 'Windows'; }
		if (navigator.appVersion.indexOf('Mac') !== -1) { return 'MacOS'; }
		if (navigator.appVersion.indexOf('X11') !== -1) { return 'UNIX'; }
		if (navigator.appVersion.indexOf('Linux') !== -1) { return 'Linux'; }
	}

	// Global selectors
	var htmlTag = document.getElementsByTagName('html')[0];

	// Determine touch from modernizr class
	var browserTouch = false;
	if ($(htmlTag).hasClass('touch')) { browserTouch = true; }


	// Flyout Menu
	// https://github.com/adchsm/Slidebars
	function handleFlyoutMenu() {

		$.slidebars();

		// Slidebars Submenus
		$('.sb-toggle-submenu').off('click').on('click', function () {
			$submenu = $(this).parent().children('.sb-submenu');
			$(this).add($submenu).toggleClass('sb-submenu-active'); // Toggle active class.

			if ($submenu.hasClass('sb-submenu-active')) {
				$submenu.slideDown(200);
			} else {
				$submenu.slideUp(200);
			}
		});

	}

	// Headroom
	// https://github.com/WickyNilliams/headroom.js
	function handleBacktotop() {

		// Back to top
		var header = document.querySelector('#back-to-top');
		var backtotop = new Headroom(header, {
			tolerance: {
				down: 5,
				up: 5
			},
			offset: 600,
			classes: {
				// when element is initialised
				initial: 'topcontrol',
				// when scrolling up
				pinned: 'topcontrol--pinned',
				// when scrolling down
				unpinned: 'topcontrol--unpinned',
				// when above offset
				top: 'topcontrol--top',
				// when below offset
				notTop: 'topcontrol--not-top'
			}
		});

		backtotop.init();

	}


	// Search
	function handleSearchToggle() {

		// Search Reset
		function searchReset() {
			$('#search-toggle').removeClass('search-toggle--open');
			$(htmlTag).removeClass('st-active');
		}

		// Toggle Search
		$('#search-toggle-control').on('touchstart click', function (e) {
			if ($('#search-toggle').hasClass('search-toggle--open')) {
				searchReset();
			}
			else {
				searchReset();
				$('#search-toggle').addClass('search-toggle--open');
				$(htmlTag).addClass('st-active');
				setTimeout(function () {
					$('#headerSearch').focus();
				}, 100);
			}
			e.stopPropagation(); e.preventDefault();
		});

		// Click Outside to close
		$(document).on('touchstart click', function (e) {
			var container = $('#search-toggle');

			if (!container.is(e.target) && container.has(e.target).length === 0) {
				searchReset();
			}

			e.stopPropagation();
		});
	}

	// Match Heights
	// https://github.com/liabru/jquery-match-height
	function handleMatchHeights() {

		$('.footer-nav .col').matchHeight();

		if ($('.bio').length) {
			$('.bio').matchHeight();
		}

	}

	// Smooth Scroll Anchors
	// https://github.com/cferdinandi/smooth-scroll
	function handleSmoothScroll() {

		smoothScroll.init({
			speed: 500,
			easing: 'easeInOutCubic',
			updateURL: true,
			offset: 62
		});

	}

	// Accordions
	// Pulled from old site
	function handleAccordions() {

		$('.extranet-category ul').hide();

		$('.extranet-category a').click(function () {
			$(this).toggleClass('active');
			$(this).next('ul').slideToggle('normal');
			return true;
		});

		$('.extranet-category.active ul').show(function () {
			$(this).prev('a').addClass('active');
		});

	}

	// List splitters
	function handleListSplitters() {

		$('ul.split').easyListSplitter({
			colNumber: 2,
			direction: 'horizontal'
		});

		if ($('ul.locations-listing').length) {
			$('ul.locations-listing').easyListSplitter({
				colNumber: 4,
				direction: 'horizontal'
			});
		}

	}

	// Plant List
	function handleEdiPlantListChange() {
		$('#Selectplantlist').change(function (e) {
			e.preventDefault();
			var planturl = 0;
			planturl = this.value;
			if (planturl !== '') {
				window.location.href = planturl;
			}
		});
	}

	//ack cr s
	function handleEdiRegionChange() {
		$('#edi_region').change(function (e) {
			e.preventDefault();
			var SearchRegion = 0;
			SearchRegion = this.value;
			console.log('here');

			if (!$(this).val()) {
				$('#divSelectPlant').hide();// $('#SearchPractice').removeAttr('disabled');

			}
			else {
				$('#divSelectPlant').show(); // $("#SearchPractice").attr('disabled', 'disabled');                

				$.ajax({
					type: 'GET',
					url: '/assets/ajax/GetRelatedPlant.ashx',
					data: { region: SearchRegion }
				}).done(function (data) {
					//  console.log(JSON.parse(data));
					$('#Selectplantlist').html(JSON.parse(data));

				}).always(function () {

				}).fail(function (request, status, error) {
				});
			}
		});
	}

	function handleVideoThumbnail() {
		$('.video-embed').on('click', function () {
			$(this).addClass('playing');
			var iframe = $(this).find('iframe');
			var video = $(this).find('video');
			if (iframe.length) {
				var src = iframe.get(0).src;
				if (src.indexOf('?') > -1) {
					iframe.get(0).src += '&autoplay=1';
				} else {
					iframe.get(0).src += '?autoplay=1';
				}
			} else if (video.length) {
				video.get(0).play();
				video.get(0).setAttribute("controls", "controls")
			}
		});
	}



	function handleDDVideo() {
		$(".DD-video_play-button").click(function (e) {
			e.preventDefault();
			var $video = $($(this).attr("href"));
			if ($video.length) {
				var video = $video.get(0);
				video.play();
				if (video.requestFullscreen) {
					video.requestFullscreen();
				} else if (video.mozRequestFullScreen) {
					video.mozRequestFullScreen();
				} else if (video.webkitRequestFullscreen) {
					video.webkitRequestFullscreen();
				} else if (video.msRequestFullscreen) {
					video.msRequestFullscreen();
				}
				$(this).hide();
			}
		});

		var pauseDDVideos = function () {
			$(".DD-video_play-button").show();
			$(".DD-video").each(function () { this.pause(); });
		};

		$("#DD-videos-slider").responsiveSlides({
			auto: false,
			speed: 600,
			timeout: 3000,
			pager: false,
			nav: true,
			random: false,
			pause: false,
			pauseControls: false,
			prevText: 'Previous',
			nextText: 'Next',
			maxwidth: '',
			navContainer: '',
			namespace: 'rslides',
			before: function () { },
			after: pauseDDVideos
		});
	}

	return {

		// GLOBAL

		init: function () {
			handleFlyoutMenu();
			handleBacktotop();
			handleSearchToggle();
			handleMatchHeights();
			handleSmoothScroll();
			handleAccordions();
			handleListSplitters();
			handleEdiRegionChange();
			handleEdiPlantListChange();
			handleVideoThumbnail();
			handleDDVideo();
		},

		// PAGE SPECIFIC

		// Home Preloader
		_Preloader: function () {

			// Images Loaded
			// https://github.com/desandro/imagesloaded

			var countImages = $('#sb-site img').size();

			$('.progress__logo').addClass('fade-in');

			function imagesDone() {

				// When all images have loaded
				$('.imagesLoaded').addClass('complete');
				$('body').removeClass('loading');
				$('.progress').fadeOut();

				// Initial Reveal
				App._InitialReveal();

				// Trigger Resize event for RevealFX
				$(window).trigger('resize');

				// If HTML5 video is supported and #video-viewport exists
				if ($(htmlTag).hasClass('video') && $('#video-viewport').length) {
					// Play video
					$('#video-viewport video').get(0).play();
				}

				// If #isotope exists
				if ($('#isotope').length) {
					// Init Isotope
					App._Isotope();
				}
			}

			$('#sb-site').imagesLoaded().always(function (instance) {
				imagesDone();
			}).progress(function (instance, image) {
				if (image.isLoaded) {
					$(image.img).addClass('loaded');

					var countLoadedImages = $('#sb-site img.loaded').size();

					var width = Number(100 * (countLoadedImages / countImages));
					width = width.toFixed(2) + '%';

					$('.progress__bar').css({
						'width': width
					});
				}
			});

			// Optionally disable preloader for testing
			imagesDone();

		},

		// Height Adjustments
		_HeightAdjustments: function () {

			var checkHeight = function () {

				// Get viewport height
				var winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
				var fsOffset = 80;

				// If fullscreen Banner exists
				if ($('#fs-banner').length) {
					var fsMiddle = document.getElementById('fs-banner');
					fsMiddle.style.height = winHeight - fsOffset + 'px';
				}

				// If slices exist
				if ($('.slice').length) {
					$('.slice').each(function (index, value) {
						$(this).css('height', winHeight - '80' + 'px');
					});
				}
			};

			var resetHeight = function () {

				// If fullscreen Banner exists
				if ($('#fs-banner').length) {
					var fsMiddle = document.getElementById('fs-banner');
					fsMiddle.style.height = '0';
				}

				// If slices exist
				if ($('.slice').length) {
					$('.slice').each(function (index, value) {
						$(this).css('height', '0');
					});
				}
			};

			var adjustHeight = function () {

				// Only adjust above 992
				if ($(window).width() > 992) {
					checkHeight();
				} else {
					resetHeight();
				}

			};

			// Adjust on first load
			adjustHeight();

			// Adjust on resize
			$(window).resize(_.debounce(function () {
				adjustHeight();
			}, 100));

		},

		_HomeBannerMedia: function () {

			// Video Cover Effect
			// http://jsfiddle.net/GCtMm/
			// Alternative: https://github.com/aMoniker/jquery.videocover

			var minW = 320; // Minimum video width allowed
			var vidWOrig;  // Original video dimensions
			var vidHOrig;

			vidWOrig = parseInt($('#video-viewport video').attr('width'));
			vidHOrig = parseInt($('#video-viewport video').attr('height'));


			function resizeToCover() {
				// Set the video viewport to the window size
				$('#video-viewport').width($(window).width());
				$('#video-viewport').height($(window).height());

				// Use largest scale factor of horizontal/vertical
				var scaleH = $(window).width() / vidWOrig;
				var scaleV = $(window).height() / vidHOrig;
				var scale = scaleH > scaleV ? scaleH : scaleV;

				// Don't allow scaled width < minimum video width
				if (scale * vidWOrig < minW) { scale = minW / vidWOrig; }

				// Scale the video
				$('#video-viewport video').width(scale * vidWOrig);
				$('#video-viewport video').height(scale * vidHOrig);

				// Center it by scrolling the video viewport
				$('#video-viewport').scrollLeft(($('#video-viewport video').width() - $(window).width()) / 2);
				$('#video-viewport').scrollTop(($('#video-viewport video').height() - $(window).height()) / 2);
			}


			// If HTML5 video is supported and not touch device
			if ($(htmlTag).hasClass('video') && !browserTouch) {

				$(window).resize(_.debounce(function () {
					resizeToCover();
				}, 100));

				$(window).trigger('resize');

			}

			// If touch device
			else if (browserTouch) {
				// Use Image
				App._ImageFill();
			}

			// None of the above
			else {
				// Use Image
				App._ImageFill();
			}

		},

		// Isotope
		// https://github.com/metafizzy/isotope
		_Isotope: function () {

			// Just Isotope

			if ($('#isotope').length) {
				var isoContainer = document.getElementById('isotope');
				var iso = new Isotope(isoContainer, {
					// options...
					itemSelector: '.iso',
					layoutMode: 'masonry',
					transitionDuration: 0,
					masonry: {
						columnWidth: '.iso-column-width',
					},
					gutter: 0
				});
			}

			// Isotope with filtering and animation

			if ($('#isotope-filter').length) {
				var isoFilterContainer = document.getElementById('isotope-filter');
				var isoFilter = new Isotope(isoFilterContainer, {
					// options...
					itemSelector: '.iso',
					layoutMode: 'masonry',
					animationOptions: {
						duration: 750,
						easing: 'linear',
						queue: false
					},
					masonry: {
						columnWidth: '.iso-column-width',
					},
					gutter: 0,
					filter: ':not(.hiddenTile)'
				});

				// Filter nav

				$('.filter_nav a').on('touchstart click', function (e) {
					var filterValue = $(this).attr('data-filter');

					isoFilter.arrange({
						filter: filterValue
					});

					$('.filter_nav a').removeClass('active');
					$(this).addClass('active');

					// Trigger Resize event for RevealFX
					$(window).trigger('resize');

					e.stopPropagation(); e.preventDefault();
				});
			}
		},

		// IMG Cover Effect
		// https://github.com/namniak/imgCoverEffect
		_ImageFill: function () {

			// If imagefill id exists
			if ($('#imagefill').length) {
				var TheImageFill = document.getElementById('imagefill');
				imgCoverEffect(TheImageFill, {
					// options...
					alignX: 'center',
					alignY: 'middle',
					watchResize: true
				});
			}

		},

		// Responsive Slides
		// https://github.com/viljamis/ResponsiveSlides.js

		_Slider: function () {

			var $VideoSliderSlides = $('#video-slider > li');
			$VideoSliderSlides.find('img:not([src])').css('opacity', '0');

			var pauseVideos = function () {
				var $videoembed = $('.video-embed');
				$videoembed.removeClass('playing');

				var iframe = $('.video-embed iframe');
				var video = $('.video-embed video');

				iframe.each(function () {
					if (this.src.includes('autoplay=1')) {
						this.src = this.src.replace('autoplay=1', '');
					}
				});

				video.each(function () { this.pause(); this.removeAttribute('controls'); });
			}

			var lazyLoad = function (index) {
				var $LazyLoadImage = $VideoSliderSlides.eq(index).find('[data-lazy]');

				if (!$LazyLoadImage.attr('src')) {
					$LazyLoadImage.attr('src', $LazyLoadImage.data('lazy'));

					$LazyLoadImage.on('load', function () {
						$LazyLoadImage.css('height', 'auto');
						$LazyLoadImage.css('opacity', '1');
						$LazyLoadImage.addClass('loaded');
						$VideoSliderSlides.find('img:not([src])').css('height', $LazyLoadImage.height() + "px");
					});
				}
			}

			$('#slider').responsiveSlides({
				auto: true,             // Boolean: Animate automatically, true or false
				speed: 600,             // Integer: Speed of the transition, in milliseconds
				timeout: 3000,          // Integer: Time between slide transitions, in milliseconds
				pager: true,            // Boolean: Show pager, true or false
				nav: false,             // Boolean: Show navigation, true or false
				random: false,          // Boolean: Randomize the order of the slides, true or false
				pause: false,           // Boolean: Pause on hover, true or false
				pauseControls: true,    // Boolean: Pause when hovering controls, true or false
				prevText: 'Previous',   // String: Text for the 'previous' button
				nextText: 'Next',       // String: Text for the 'next' button
				maxwidth: '',           // Integer: Max-width of the slideshow, in pixels
				navContainer: '',       // Selector: Where controls should be appended to, default is after the 'ul'
				manualControls: '',     // Selector: Declare custom pager navigation
				namespace: 'rslides',   // String: Change the default namespace used
				before: function () { },   // Function: Before callback
				after: function () { }     // Function: After callback
			});

			$("#video-slider").responsiveSlides({
				auto: false,			// Boolean: Animate automatically, true or false
				speed: 600,				// Integer: Speed of the transition, in milliseconds
				timeout: 3000,			// Integer: Time between slide transitions, in milliseconds
				pager: true,			// Boolean: Show pager, true or false
				nav: true,				// Boolean: Show navigation, true or false
				random: false,			// Boolean: Randomize the order of the slides, true or false
				pause: false,			// Boolean: Pause on hover, true or false
				pauseControls: true,	// Boolean: Pause when hovering controls, true or false
				prevText: 'Previous',	// String: Text for the 'previous' button
				nextText: 'Next',		// String: Text for the 'next' button
				maxwidth: '',			// Integer: Max-width of the slideshow, in pixels
				navContainer: '',		// Selector: Where controls should be appended to, default is after the 'ul'
				manualControls: '',		// Selector: Declare custom pager navigation
				namespace: 'rslides',	// String: Change the default namespace used
				before: function () { },   // Function: Before callback
				after: function (index) { pauseVideos(); lazyLoad(index); }     // Function: After callback
			});

			lazyLoad(0); //Load first image

			$("#video-slider-v2").responsiveSlides({
				auto: false,            // Boolean: Animate automatically, true or false
				speed: 600,             // Integer: Speed of the transition, in milliseconds
				timeout: 3000,          // Integer: Time between slide transitions, in milliseconds
				pager: false,           // Boolean: Show pager, true or false
				nav: true,				// Boolean: Show navigation, true or false
				random: false,          // Boolean: Randomize the order of the slides, true or false
				pause: false,           // Boolean: Pause on hover, true or false
				pauseControls: true,    // Boolean: Pause when hovering controls, true or false
				prevText: 'Previous',   // String: Text for the 'previous' button
				nextText: 'Next',       // String: Text for the 'next' button
				maxwidth: '',           // Integer: Max-width of the slideshow, in pixels
				navContainer: '',       // Selector: Where controls should be appended to, default is after the 'ul'
				manualControls: '#slider-pager',     // Selector: Declare custom pager navigation
				namespace: 'rslides',   // String: Change the default namespace used
				before: function () { },   // Function: Before callback
				after: pauseVideos		// Function: After callback
			});


		},

		// Initial Reveal
		// Add revealed class to any element with .initial_reveal
		_InitialReveal: function () {
			// If .initial_reveal exists
			if ($('.initial_reveal').length) {
				// Reveal something
				$('.initial_reveal').addClass('revealed');
			}
		},

		// Reveal FX
		// Find elements with .is-animated class and append .revealed class when element is in viewport
		_RevealFX: function () {

			// Add revealed class for touch
			$('.is-animated').each(function () {
				if (browserTouch) {
					classie.add(this, 'revealed');
				}
			});

			// var currentScroll = -1;


			function withinViewport() {

				// Determine scroll distance from top

				// If touch device

				var newScroll = window.pageYOffset || document.documentElement.scrollTop;

				// Add .revealed class when each element is within viewport (with offset)

				$('.is-animated').each(function () {

					// Using classie for better performance
					// https://github.com/desandro/classie

					// If (scroll distance from top) + (height of window) is greater than or equal to (element distance from top + offset)

					if (newScroll + $(window).height() >= $(this).offset().top + 50) { // && currentScroll < newScroll
						classie.add(this, 'revealed');
					}

					// If (scroll distance from top) + (height of window) is less than or equal to (element distance from top + offset)

					else if (!browserTouch && newScroll + $(window).height() <= $(this).offset().top + 250) { // && currentScroll > newScroll
						classie.remove(this, 'revealed');
					}

				});

				// currentScroll = newScroll;
			}

			// Fire on DOM ready
			withinViewport();

			// Refire on debounced resize event
			$(window).resize(_.debounce(function () {
				withinViewport();
			}, 300));

			// Refire on throttled scroll event
			$(window).scroll(_.throttle(function () {
				withinViewport();
			}, 100));

		},

		// Headroom
		// https://github.com/WickyNilliams/headroom.js
		_StickyHeader: function () {

			// Sticky Header
			var header = document.querySelector('#header');
			var stickyheader = new Headroom(header, {
				tolerance: {
					down: 5,
					up: 5
				},
				offset: 115
			});

			stickyheader.init();

		},

		_StickyHeaderHome: function () {

			// Sticky Header for homepage (bigger offset)
			var header = document.querySelector('#header');
			var stickyheader = new Headroom(header, {
				tolerance: {
					down: 5,
					up: 5
				},
				offset: 1000
			});

			stickyheader.init();

		},

		// Form Elements
		_FormElements: function () {

			// Custom Select
			// https://github.com/adamcoulombe/jquery.customSelect

			$('.custom-select').customSelect();

			// Adjust on resize
			$(window).resize(_.debounce(function () {
				$('.custom-select').trigger('render');
			}, 300));

		},

		// Overlay
		_Overlay: function () {

			// Toggle overlay on click
			$('#sb-site, .overlay').on('touchstart click', '.toggle-overlay', function (e) {

				// Determine url for jquery load
				var overlayContentUrl = $(this).data('content-url');

				// If overlay is open
				if (classie.has(htmlTag, 'show-overlay')) {
					classie.remove(htmlTag, 'show-overlay');

					// For windows scrollbar fix
					if (whatOS() === 'Windows') {
						classie.remove(htmlTag, 'win-show-overlay');
					}
				}

				// If overlay is not open
				else {
					classie.remove(htmlTag, 'show-overlay');
					classie.add(htmlTag, 'show-overlay');

					// For windows scrollbar fix
					if (whatOS() === 'Windows') {
						classie.remove(htmlTag, 'win-show-overlay');
						classie.add(htmlTag, 'win-show-overlay');
					}

					// Load content
					$('#overlay__inner__page').load(overlayContentUrl + ' .overlay__inner__page__content', function () {

						// Scroll to top
						$('.overlay').animate({
							scrollTop: '0'
						}, 0);

						// FitVids
						if ($('.overlay iframe').length) {
							$('.overlay').fitVids();
						}

					});
				}

				e.stopPropagation();
				e.preventDefault();

				return false;
			});

			// stop iframe video on close
			$('.toggle-overlay').click(function () {
				$('#lear-video').find('iframe').attr('src', jQuery('#lear-video iframe').attr('src'));
			});

		},

		_ProductsSubMenu: function () {
			var $activeBrands = $('.type-73.active');
			if ($activeBrands.length) {
				var $activeCategories = $('.type-71.active');
				$activeCategories.removeClass('active');
			}
		},

		_Odometer: function () {
			//function createCookie(name, value, days) {
			//	if (days) {
			//		var date = new Date();
			//		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			//		var expires = "; expires=" + date.toGMTString();
			//	}
			//	else var expires = "";

			//	document.cookie = name + "=" + value + expires + "; path=/";
			//}

			//function readCookie(name) {
			//	var nameEQ = name + "=";
			//	var ca = document.cookie.split(';');
			//	for (var i = 0; i < ca.length; i++) {
			//		var c = ca[i];
			//		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			//		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
			//	}
			//	return null;
			//}

			//function eraseCookie(name) {
			//	createCookie(name, "", -1);
			//}

			var scheme = document.location.protocol == "https:" ? "wss" : "ws",
				port = document.location.port ? (":" + document.location.port) : "";
			var connectionUrl = scheme + "://" + document.location.hostname + port + "/Assets/WebSockets/DDHandler.ashx";

			var ws = new WebSocket(connectionUrl);



			var $odometer = $("#odometer");

			od = new Odometer({
				el: $odometer.get(0),
				value: 0,

				// Any option (other than auto and selector) can be passed in here
				format: '(,ddd)',
			});

			var count = $odometer.data('val').toString();

			od.update(count);

			ws.onmessage = function (evt) {
				od.update(evt.data);
			};

			ws.onerror = function (evt) {
				console.error(evt.message);
			};


			//if (readCookie("DDPledge") != null) {
			//	$("#pledgeform").prepend("<p>Thank you for taking the pledge!</p>");
			//}

			$("#pledgeform").submit(function (e) {
				e.preventDefault();

				var $form = $(this);
				var loader = ["Submitting&nbsp;&nbsp;&nbsp; ", "Submitting.&nbsp;&nbsp; ", "Submitting..&nbsp; ", "Submitting...", "Submitting&nbsp;..", "Submitting&nbsp;&nbsp;."];
				//var loader = ["Loading⠄⠄⠄", "Loading⠂⠄⠄", "Loading⠁⠂⠄", "Loading⠂⠁⠂", "Loading⠄⠂⠁" , "Loading⠄⠄⠂"];
				var len = loader.length - 1;
				var i = 0;
				var timeout;
				var $button = $form.find('button');

				$.ajax({
					type: "POST",
					url: "/assets/ajax/PostDDPledge.ashx",
					data: $form.serialize(),
					beforeSend: function () {
						$form.find(':input:not(:disabled)').prop('disabled', true);

						$button.width($("#load-more-button a").width());

						$button.get(0).innerHTML = loader[i];

						timeout = setInterval(function () {
							$button.get(0).innerHTML = loader[i];
							i = (i < len) ? i + 1 : 0
						}, 200);
					},
				}).done(function (data) {

					clearInterval(timeout);

					if (ws.readyState == WebSocket.OPEN) {
						ws.send("");
					}

					var $formcontent = $('#pledgeform--content');
					var $success = $('#pledgeform--success');
					theHeight = $success.height();
					$formcontent.css("opacity", "0");

					$("#pledgeform--container").addClass("complete");

					$("#pledgeform--title").html("Thank you for taking the pledge");

					$('#pledgeform--container_inner').animate({ height: theHeight }, 500, function () {

						//notice the `style` attribute of the new HTML is being reset so it's not displayed off-screen anymore
						$formcontent.css({ "position": "absolute", "z-index": "-1" });
						$success.css({ "position": "relative", "z-index": "1", "opacity": "1" });

						$(this).attr('style', '');
					});


				}).always(function () {

				}).fail(function (request, status, error) {
					console.error(error);
				});


			});

			window.onbeforeunload = function () {
				ws.onclose = function () { }; // disable onclose handler first
				ws.close()
			};
		},
	};
}());

$(document).ready(function () {
	App.init();



	//!function (a) { if (!a.Localize) { a.Localize = {}; for (var e = ["translate", "untranslate", "phrase", "initialize", "translatePage", "setLanguage", "getLanguage", "detectLanguage", "getAvailableLanguages", "untranslatePage", "bootstrap", "prefetch", "on", "off"], t = 0; t < e.length; t++) a.Localize[e[t]] = function () { } } }(window);


	//     Localize.initialize({
	//         key: '3a0KiiigAkK46',
	//         rememberLanguage: true
	//     });

	//     $(".siteSearch").submit(function (searchForm) {


	//       var currentLanguage = Localize.getLanguage();

	//       if (currentLanguage != null && currentLanguage != "en" && currentLanguage != "source")
	//        {

	//           $(this).find(".searchLanguage").val(currentLanguage);

	//         }


	//    });


});

